import React from 'react'
import cn from 'classnames'

import { iconMappings, IconNames } from 'brand-assets/icon-lib'

const sizes = {
  micro: 10,
  mini: 12,
  small: 16,
  'semi-medium': 20,
  medium: 24,
  large: 32,
  'semi-large': 36,
  'x-large': 40,
  '2x-large': 44,
  '3x-large': 48,
}

// also used by button component
export type Sizes = keyof typeof sizes

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconNames
  size?: Sizes | number
  className?: string
  color?: string
  onClick?: (e: React.MouseEvent) => void
}

const Icon: React.FC<IconProps> = ({
  name,
  size,
  className,
  color = 'currentColor',
  ...rest
}): JSX.Element | null => {
  const IconComponent = iconMappings[name] || iconMappings['shooting-star']

  if (rest.fill) {
    throw new Error('Icon prop fill is not supported, use color instead')
  }

  const defaultProps: any = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: sizes.medium,
    height: sizes.medium,
    viewBox: '0 0 32 32',
  }

  if (size) {
    if (typeof size === 'string') {
      defaultProps.width = sizes[size]
      defaultProps.height = sizes[size]
    } else {
      defaultProps.width = size
      defaultProps.height = size
    }
  }

  if (color) {
    defaultProps.style = { color }
  }

  const classNames = cn(name, className)
  return React.createElement(IconComponent, {
    ...defaultProps,
    className: classNames,
    ...rest,
  })
}

export { Icon }
export type { IconNames }
