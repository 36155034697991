import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useLazyQuery } from '@apollo/client'

import { isBrowser } from 'lib/utils'

import {
  COUNTRY_ROUTE,
  COUNTRY_ROUTE_V1,
  DESTINATION_ROUTE,
  DESTINATION_ROUTE_V1,
  DESTINATION_ROUTE_V2,
  HOME_ROUTE,
  PRODUCT_ROUTE,
  SEARCH_ROUTE,
} from 'lib/constants/routes'
import { CAMPAIGN_TYPE } from 'lib/constants'

import { CAMPAIGN_QUERY } from 'gql'

import useRouteMatch from './useRouteMatch'

const usePopupOverlaySequence = ({ isPromoEnabled = false }: { isPromoEnabled?: boolean }) => {
  // This hook is used to sequence the below popup/overlays on the page.
  // TTD Popup on PDP page
  // Campaign banner overlay on any page
  // Google one tap overlay on any page

  const router = useRouter()
  const isPdpPage = useRouteMatch(PRODUCT_ROUTE)
  const [getCampaign, { data, called: campaignCalled, loading: campaignLoading }] = useLazyQuery(
    CAMPAIGN_QUERY.query
  )
  const isCampaignCallFinished = campaignCalled && !campaignLoading
  // taken seperate state of page render to make sure required resources reflect correctly.
  const [isPageRendered, setPageRendered] = useState(false)

  const getQuery = useCallback(() => {
    if (isPdpPage && isBrowser) {
      // useRouter hook not working properly for cached page (PDP) to get query params hence native javascript way is used
      // assumption is cached page is render immediately while nextjs resources are yet to load or not ready
      const urlSearchParams = new URLSearchParams(window?.location?.search)
      const queryParams = Object.fromEntries(urlSearchParams.entries())
      return queryParams
    }
    return router.query
  }, [router, isPdpPage])

  const isEligibleForTTDPromo = () => {
    if (!isPdpPage || !isPromoEnabled) return false

    const { utm_medium, utm_campaign } = getQuery()
    if (utm_medium === 'ttd' && !utm_campaign) return true

    return false
  }

  const { isTTDUser } = useMemo(() => {
    if (!isBrowser || !isPageRendered) return { isTTDUser: false }

    const isTTDUser = isEligibleForTTDPromo()

    return { isTTDUser }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageRendered, router.asPath])

  useEffect(() => {
    setPageRendered(true)
  }, [])

  useEffect(() => {
    if (!campaignCalled) {
      const { utm_campaign: utmCampaign = '', utm_source: utmSource = '' } = getQuery()
      getCampaign({ variables: { campaignId: utmCampaign, campaignSource: utmSource } })
    }
  }, [campaignCalled, getCampaign, getQuery])

  const campaignData = useMemo(() => {
    const _campaignData = data?.[CAMPAIGN_QUERY.queryName]

    if (!(isCampaignCallFinished && _campaignData?.campaignId)) return null

    if (_campaignData?.campaignType === CAMPAIGN_TYPE.SEASONAL_CAMPAIGN) {
      const pathName = `${router.pathname}${router.pathname.endsWith('/') ? '' : '/'}`
      const campaignForPages = _campaignData?.pages || []
      const PAGES_TO_CONSTANT_MAPPING: Record<string, string> = {
        [HOME_ROUTE]: 'HOME',
        [DESTINATION_ROUTE]: 'DESTINATION',
        [DESTINATION_ROUTE_V1]: 'DESTINATION',
        [DESTINATION_ROUTE_V2]: 'DESTINATION',
        [COUNTRY_ROUTE]: 'COUNTRY',
        [COUNTRY_ROUTE_V1]: 'COUNTRY',
        [PRODUCT_ROUTE]: 'PDP',
        [SEARCH_ROUTE]: 'SEARCH',
      }
      if (
        campaignForPages.indexOf('ALL') > -1 ||
        campaignForPages.indexOf(PAGES_TO_CONSTANT_MAPPING[pathName]) > -1
      )
        return _campaignData
      return null
    }

    return _campaignData
  }, [data, isCampaignCallFinished, router.pathname])

  // disable GTM for automation as crashing 3rd party scripts in GTM causing tests to fail
  // crashing blocks the next possible UI elements to be render or interact
  const isGtm = getQuery().utm_source !== 'test_automation'

  if (campaignData) return { campaignData, isGtm }
  if (isCampaignCallFinished && isTTDUser) return { isTTDUser, isGtm }

  return { isGtm }
}

export { usePopupOverlaySequence }
