import { ParsedUrlQuery } from 'querystring'

import cookie from 'cookie'
import qs from 'query-string'
import * as AES from 'crypto-js/aes'
import * as SHA256 from 'crypto-js/sha256'
import utf8Enc from 'crypto-js/enc-utf8'
import uuid from 'uuid-random'
import countryToCurrency from 'country-to-currency'

import Logger from 'lib/logger'
import { sift } from 'lib/utils/sift'
import { RouteParams, RouteParamsVariable } from 'lib/@Types'

import { PRIVATE_ROUTES, PRODUCT_ROUTE } from 'lib/constants/routes'
import {
  UNSAFE_URL_CHARACTERS,
  UTM_STORAGE_KEY,
  COOKIES_CURRENCY,
  COOKIES_USER_LOCALE,
  COOKIES_DS_SESSION_ID,
  DATA_LAYER_EVENT,
  COOKIES_LAST_CLICK,
  COOKIES_FIRST_CLICK,
  COOKIES_PARTNER_SESSION_ID,
  PARTNER_LIST,
  PARTNER_DEFAULT_COUNTRY_CODE,
  FALLBACK_CURRENCY,
  PARTNER_DEFAULT_CURRENCY,
  COOKIES_DS_USER_ID,
} from 'lib/constants'

export const isServer = typeof window === 'undefined'

export const isBrowser = typeof window !== 'undefined'

export const userAgent = isBrowser ? window?.navigator?.userAgent : ''

export const isChromeBrowser = (userAgent: string) => /chrome|crios/i.test(userAgent)

export const isNumber = (number?: string | number | null): boolean => {
  if (number === null || number === undefined) return false

  return !isNaN(+number)
}

export const noop = () => undefined

export const concatUniq = (arr: any[], ...rest: any[]) => Array.from(new Set(arr.concat(...rest)))

// we should not be creating date instances from date string (date string without time zone) directly as the date string parameter will be treatd as UTC string
// deconstruct the date string and create the instance manually instead
// can find more details here: https://css-tricks.com/everything-you-need-to-know-about-date-in-javascript/
// following function expects date string in YYYY-MM-DD format or YYYY-MM-DDT00:00:00[Z] format
export const dateStringToLocalDate = (dateString: any) => {
  const [_dateString] = dateString.split('T')
  const dateArray = _dateString.split('-')
  const [year, month, day] = dateArray
  return new Date(year, month - 1, day)
}

export const dateTimeStringToLocalDate = (dateString: any) => {
  const [_dateString, _timeString] = dateString.replace(' ', 'T').split('T')
  const dateArray = _dateString.split('-')
  const timeArray = _timeString.split(':')
  const [year, month, day] = dateArray
  const [hour, minute = '00', second = '00'] = timeArray

  return new Date(year, month - 1, day, hour, minute, second)
}

export const dateToISOString = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()

export const stringifyDate = (date: Date) => dateToISOString(date).split('T')[0]

export const stringifyTime = (date: Date) => dateToISOString(date).split('T')[1]

export const normalizeStr = (str: string) => String(str).toLowerCase().replace(UNSAFE_URL_CHARACTERS, '-')

// list of words we should ignore smart casing
const ignoreCaseList = ['to', 'is', 'and', 'or', 'a', 'of', '&', 'with', 'on']

const brands = ['KrisFlyer', 'Pelago', 'SQ']

export const titleCase = (text: string, ignoreConjunctions = false) => {
  if (!text || !text.trim()) return text

  return text?.replace?.(/\w\S*/g, (t) => {
    if (ignoreConjunctions && ignoreCaseList.includes(t)) return t
    if (brands.includes(t)) return t

    return t.charAt(0).toUpperCase() + t.substring(1).toLowerCase()
  })
}

// text words count more than titleCaseLimit? => return sentence case
// text words count lesser than or equal to titleCaseLimit? => return title case (title case will not be applied to conjunctions)
export const smartCase = (text: string, titleCaseLimit: 1 | 2 | 3 | 'none' = 3) => {
  if (!text) return text

  const words = text?.match(/\w\S*/g)
  if (!words) return text

  const wordsWithoutConjunction = words.filter((t) => !ignoreCaseList.includes(t))

  // change it the text to sentence case
  if (titleCaseLimit !== 'none' && wordsWithoutConjunction?.length > titleCaseLimit) {
    let index = 0
    return text?.replace?.(/\w\S*/g, (t) => {
      index += 1
      if (brands.includes(t)) return t
      if (index === 1) return t.charAt(0).toUpperCase() + t.substring(1).toLowerCase()

      return t?.toLowerCase()
    })
  }

  return titleCase(text, true)
}

export const sentenceCase = (text: string | undefined) => {
  if (!text || typeof text !== 'string') return ''

  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
}

export const smallCase = (text?: string) => {
  if (!text) return text

  return text.toLowerCase()
}

export const getError = (response: any) => {
  if (!response) return response
  return Array.isArray(response) ? response[0] : response
}

export const getErrorInfo = (
  response: any
): { code: number; errorMessage: string; errorHeader?: string } | null => {
  const _response = getError(response)

  if (_response?.code < 300) return null
  return _response
}

export const constructErrorFromObject = (error: any) => {
  return typeof error === 'object'
    ? new Error(
        Object.entries(error)
          .map(([key, value]) => `${key}: ${value}`)
          .join(', ')
      )
    : error
}

// Sentry setup: https://pelago.atlassian.net/wiki/spaces/PEL/pages/1887174657/SSR+-+Sentry+Integration
export const logError = (error: any, extra?: any) => {
  if (isBrowser) {
    window?.Sentry?.captureException(error, { extra })
  } else {
    // log errors via Winston logger which will be used by datadog
    Logger?.getInstace()?.error(error, { extra })
  }
}

export const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()

export const getIdFromSlug = (slug: string) => (slug ? slug.split('-')[0] : -1)

export const getDestinationIdFromSlug = (slug: string) => {
  if (slug) {
    const slugs = slug.split('-')
    return slugs[slugs.length - 1]
  }
}

export const buildSlug = ({ id, name = '', uri, destinationId = '' }: any = {}): string => {
  const dest = `${destinationId ? `-${destinationId}` : ''}`
  const optional = uri || name ? `-${uri || normalizeStr(name)}${dest}` : ''
  return `${id}${optional}`
}

// Sentry setup: https://pelago.atlassian.net/wiki/spaces/PEL/pages/1887174657/SSR+-+Sentry+Integration
export const logInfo = (message: string) => {
  if (isBrowser) {
    window?.Sentry?.captureMessage(message, { level: 'info' })
  } else {
    // log info via Winston logger which will be used by datadog
    Logger?.getInstace()?.info(message)
  }
}

// Temp logic to decide whether to fingerprint device or not
export const abTestShouldFingerPrint = (dsuserId: string) => {
  const x = dsuserId.split('-').reduce((acc, curr) => acc + Number.parseInt(curr, 16), 0)
  // 60% of traffic
  return x % 5 <= 1
}

export const serialiseCookie = (name: string, value: string, maxAge = 365 * 24 * 60 * 60) =>
  cookie.serialize(name, value, {
    maxAge,
    path: '/',
  })

export const setBrowserCookie = (name: string, value: string, maxAge = 365 * 24 * 60 * 60) => {
  if (isServer) return

  document.cookie = cookie.serialize(name, value, {
    maxAge: maxAge,
    path: '/',
  })
}

export const getBrowserCookie = (name: string) => {
  if (isServer) return

  const cookies = cookie.parse(document.cookie)
  return cookies[name]
}

export const aesEncrypt = (text: string, key: string) => AES.encrypt(text, key).toString()

export const aesDecrypt = (text: string, key: string) => AES.decrypt(text, key).toString(utf8Enc)

export const hashText = (text: string) => SHA256.default(text).toString()

// generatePath('city/[destinationId]', { destinationId: 'singapore', }) => will become city/singapore
const generatePath = (routePath: string, routeParams: any = {}): string => {
  if (!Object.keys(routeParams).length) return routePath

  return Object.keys(routeParams).reduce((modifedRoutePath: string, routeParamKey: string) => {
    const newRoutePath = modifedRoutePath.replace(`[${routeParamKey}]`, routeParams[routeParamKey])
    return newRoutePath
  }, routePath)
}

// checks the exact match
export const matchPath = (pathname: string, comparingPath: string) => {
  const [pathWithoutQueryString] = pathname.split('?')

  const comparingPathArray = comparingPath.split('/').filter(Boolean)
  const givenPathArray = pathWithoutQueryString.split('/').filter(Boolean)

  if (comparingPathArray.length === givenPathArray.length) {
    return comparingPathArray.every((item: string, index: number) => {
      if (item === givenPathArray[index]) return true
      if (item.substring(0, 1) === '[' && item.substring(item.length - 1) === ']') return true
      return false
    })
  }
  return false
}

export const isPrivateRoute = (route = ''): boolean =>
  !!PRIVATE_ROUTES.find((r: string) => matchPath(route, r))

const CLICK_IDS = ['gclid']

export const getStringifiedUtmParams = (
  queryParams: ParsedUrlQuery,
  additionalInfo?: Record<string, string>
): [string, string, boolean] => {
  if (additionalInfo?.referer) {
    const isStripeHookForPaymentConfirmation = additionalInfo?.referer?.includes?.('hooks.stripe')
    const isKfSignIn = queryParams?.oAuthProvider === 'KRISFLYER' || !!queryParams?.cvsKey // payment page login to krisflyer to do payment
    const isRefererFromExcludeAttributionList = isStripeHookForPaymentConfirmation || isKfSignIn

    if (isRefererFromExcludeAttributionList)
      return ['', JSON.stringify(additionalInfo), isRefererFromExcludeAttributionList]
  }

  const params = Object.entries(queryParams).reduce((acc: any, [key, val]) => {
    if (CLICK_IDS.includes(key)) {
      acc['click_id'] = Array.isArray(val) ? val.join(',') : val
      return acc
    }

    if (!key.startsWith('utm_') || key.length <= 4) {
      return acc
    }

    const keyWithoutUtmPrefix = key.slice(4)
    const _val = Array.isArray(val) ? val.join(',') : val
    acc[keyWithoutUtmPrefix] = _val

    return acc
  }, {})

  return Object.keys(params)?.length
    ? [JSON.stringify(params), JSON.stringify({ ...params, ...additionalInfo }), false]
    : ['', JSON.stringify(additionalInfo), false]
}

const TYPE_TO_COOKIE_NAME_MAPPING = {
  lastClick: COOKIES_LAST_CLICK,
  firstClick: COOKIES_FIRST_CLICK,
  default: UTM_STORAGE_KEY,
}

export const getUtmParams = (type: 'lastClick' | 'firstClick' | 'default' = 'default') => {
  let result = {}
  const cookieName = TYPE_TO_COOKIE_NAME_MAPPING[type]
  const utmData = getBrowserCookie(cookieName)
  if (utmData?.trim?.()) {
    try {
      result = JSON.parse(utmData)
    } catch (e) {
      //
    }
  }
  return result
}

/**
 * Extract locale and build path using React router helpers
 * @param {String} routePath
 * @param {Object | null} params
 * @param {Object | null} search
 * @returns String
 */
export const buildPath = <T extends string>(
  routePath: T,
  params?: RouteParamsVariable<T> extends never ? object : RouteParams<T>,
  search?: object
): string => {
  const _params: any = params || {}
  const _search = qs.stringify(search || {})

  const normalizedParams = Object.keys(_params).reduce((newParams: any, key) => {
    // TODO Akarsh - replace with normalize helper above (pending on BE to fix case sensitivity booking ID)
    const paramsString = typeof _params[key] === 'string' ? _params[key] : _params[key]?.toString()
    newParams[key] = paramsString?.replace(UNSAFE_URL_CHARACTERS, '-')
    return newParams
  }, {})
  let path = generatePath(routePath, {
    ...normalizedParams,
  })

  if (_search) path += `?${_search}`

  return decodeURIComponent(path)
}

export const getCookieCurrency = () => getBrowserCookie(COOKIES_CURRENCY)

export const setCookieCurrency = (currency: string) => setBrowserCookie(COOKIES_CURRENCY, currency)

export const setCookieUserLocale = (locale: string, maxAge?: number) =>
  setBrowserCookie(COOKIES_USER_LOCALE, locale, maxAge)

export const itemsAreEmpty = <T>(items: T[] | undefined): items is undefined =>
  !Array.isArray(items) || items.length === 0

export const RICH_TEXT_LINK_CLASSNAME = 'g--rich-text-link'
export const replacePseudoLinks = (content: string) => {
  try {
    return content
      .replace(/\[link-([a-zA-Z]+)-(\w+)-?(.+?)?\](.+?)\[\/link\]/gim, (match, type, id, uri, text) => {
        return `<a href="${buildPath(PRODUCT_ROUTE, {
          productSlug: `${id}-${uri}`,
        })}" class="link ${RICH_TEXT_LINK_CLASSNAME}" target="_blank">${text}</a>`
      })
      .replace(/\[external-link-(.+?)?\](.+?)\[\/external-link\]/gim, (match, href, text) => {
        return `<a href="${href}" class="link ${RICH_TEXT_LINK_CLASSNAME}" target="_blank" rel="noopener noreferrer">${text}</a>`
      })
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getQueryLists = () => {
  const phoneQueryList = window.matchMedia(`(max-width: 600px)`)
  const tabletQueryList = window.matchMedia(`(max-width: 768px)`)
  return { phoneQueryList, tabletQueryList }
}

export const isVerticallyFullyVisible = (ele: any, container: any) => {
  if (!ele || !container) return false

  const { bottom, top } = ele.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  return top >= containerRect.top && bottom <= containerRect.bottom
}

export const isHorizontallyFullyVisible = (ele: any, container: any) => {
  if (!ele || !container) return false

  const { right, left } = ele.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  return left >= containerRect.left && Math.floor(right) <= containerRect.width + containerRect.left
}

export const isFullyVisible = (
  ele: any,
  container: any,
  direction: 'horizontal' | 'vertical' = 'vertical'
) => {
  if (direction === 'horizontal') return isHorizontallyFullyVisible(ele, container)
  return isVerticallyFullyVisible(ele, container)
}

export const range = (start: number, end: number) => {
  const length = end - start + 1
  return Array.from({ length }, (_, i) => start + i)
}

export const collapseWhiteSpaces = (str: string) => str?.toString().replace(/ +(?= )/g, '') // limit to 1 space around characters

export const encodeStr = (str: string) => window.encodeURIComponent(collapseWhiteSpaces(str))

export const buildSearchParams = (paramsObj: any) => {
  const search = Object.keys(paramsObj).reduce((acc, key, i) => {
    const value = paramsObj[key]
    if (value == null || value === '') return acc
    return `${acc}${i === 0 ? '' : '&'}${encodeStr(key)}=${encodeStr(value)}`
  }, '?')

  return search === '?' ? '' : search
}

export const rangeFromZero = (numOfItems: any) => Array.from(Array(numOfItems).keys())

export const scrollTo = (element: Element, offset = 0, behavior: ScrollBehavior = 'smooth') => {
  if (isServer || !element || typeof element.getBoundingClientRect !== 'function') return null
  const { top } = element.getBoundingClientRect()
  const { top: bodyTop } = document.body.getBoundingClientRect()
  const bodyStyles = window.getComputedStyle(document.body)
  const headerHeight = parseInt(bodyStyles.getPropertyValue('--header-height'), 10)
  window.scrollTo({
    top: top - bodyTop - headerHeight + offset,
    behavior,
  })
}

export const getCustomCSSVar = (name: string, element = isServer ? null : document.body) => {
  if (isServer || !element) return null
  const bodyStyles = window.getComputedStyle(element)
  return bodyStyles.getPropertyValue(name)
}

export const getSearchParams = (search: string, valueSplitter: string) => {
  if (typeof search !== 'string') return {}
  return search
    .replace(/^\?/, '')
    .split('&')
    .reduce((result, keyVal) => {
      if (!keyVal || keyVal.indexOf('=') === -1) return result
      const [key, value] = keyVal.split('=').map((s) => decodeURIComponent(s))
      return { ...result, [key]: valueSplitter ? value.split(valueSplitter) : value }
    }, {})
}

// 1/5 users should see the striked off price
export const getAbTestVersion = (dsuserId?: string, modulo = 5, matchingGroup = 4) => {
  if (!dsuserId) return false

  const x = dsuserId.split('-').reduce((acc, curr) => acc + Number.parseInt(curr, 16), 0)

  return x % modulo === matchingGroup
}

/**
 * Get relative offset values of element
 * https://stackoverflow.com/a/26230989/1248506
 */
export const getCoords = (elem: any) => {
  const box = elem.getBoundingClientRect()

  const { body } = document
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = box.top + scrollTop - clientTop
  const left = box.left + scrollLeft - clientLeft
  const bottom = top + box.height
  const right = left + box.width

  return { top, left, bottom, right }
}

export const createActivitySession = () => {
  const existingSessionId = getBrowserCookie(COOKIES_DS_SESSION_ID)
  const sessionId = existingSessionId || uuid()

  if (!existingSessionId) {
    sift.setSessionId(sessionId)
  }
  // 30 min TTL
  setBrowserCookie(COOKIES_DS_SESSION_ID, sessionId, 30 * 60)
}

// e.g. ?param1=foo,bar&param1=bat&param2=baz => { param1: ['foo', 'bar', 'bat'], param2: ['baz'] }
export const getMergedQueryParams = (queryParams: ParsedUrlQuery): Record<string, string[]> => {
  const mergedQueryParams = Object.entries(queryParams || {})?.reduce?.((acc, [key, value]) => {
    if (typeof value === 'string' && value.includes(',')) {
      return { ...acc, [key]: value.split(',') }
    }
    if (Array.isArray(value) && value.length) {
      return { ...acc, [key]: value }
    }
    if (value) {
      return { ...acc, [key]: [value] }
    }
    return acc
  }, {})

  return mergedQueryParams
}

export const productToEcommerceGtm = (product: Record<string, any>, from?: 'booking_confirmation') => {
  const ecommerceProductCategories: Record<string, string> = {},
    ecommerceProductTags: Record<string, string> = {}

  if (from === 'booking_confirmation') {
    product.subCategories?.forEach((subCategory: string, index: number) => {
      ecommerceProductCategories[
        `${DATA_LAYER_EVENT.ECOMMERCE.ITEM_SUBCATEGORY}${index !== 0 ? index + 1 : ''}`
      ] = subCategory
    })
    product.categories?.forEach((category: string, index: number) => {
      ecommerceProductCategories[
        `${DATA_LAYER_EVENT.ECOMMERCE.ITEM_CATEGORY}${index !== 0 ? index + 1 : ''}`
      ] = category
    })
  } else {
    product.productSubcategories?.forEach(({ categoryId }: { categoryId: string }, index: number) => {
      ecommerceProductCategories[
        `${DATA_LAYER_EVENT.ECOMMERCE.ITEM_CATEGORY}${index !== 0 ? index + 1 : ''}`
      ] = categoryId
    })
  }

  product.productTags?.map(({ tagId }: { tagId: string }, index: number) => {
    ecommerceProductTags[`${DATA_LAYER_EVENT.ECOMMERCE.ITEM_TAG}${index !== 0 ? index + 1 : ''}`] = tagId
    return tagId
  })
  const { productId: item_id, productName: item_name, priceRangeFrom, coupon } = product
  const commonKeyValues = {
    item_id,
    id: item_id,
    item_name,
    item_title: item_name,
    location_id: product.destinationId,
    google_business_vertical: 'custom',
    ...ecommerceProductCategories,
    ...ecommerceProductTags,
  }

  let itemIndex = 0
  const ecommerceItems: Record<string, any>[] = []
  product.options?.length
    ? product.options.map((option: Record<string, any>) => {
        return option.units.map((unit: Record<string, any>) => {
          ecommerceItems.push({
            item_option_id: option.optionId,
            item_option_name: option.optionName,
            item_variant: unit.unitName,
            index: itemIndex++,
            price: unit.sellingPrice || unit.price,
            quantity: unit.quantity,
            ...commonKeyValues,
          })
        })
      })
    : ecommerceItems.push({ index: 0, price: priceRangeFrom, quantity: 1, ...commonKeyValues })

  return {
    ecommerce: {
      currency: product.currency,
      value: product.cartValue || priceRangeFrom,
      value_sgd: product.cartValueSgd,
      coupon,
      payment_type: product.paymentType,
      items: ecommerceItems,
    },
  }
}

export const clearEcommerceToGa4 = () => {
  window?.dataLayer?.push({ ecommerce: null }) // Clear the previous ecommerce object
}

export const buildProductLinkSlugFromProduct = (product: Record<string, any>) => {
  const { productId, productUri, destinationId } = product
  let slug = `${productId}-${productUri}`
  if (destinationId) slug += `-${destinationId}`
  return slug
}

export const removeHtmlTag = (str: string) => {
  return str.replace(/(<([^>]+)>)/gi, '')
}

export const getIsAbsoluteUrl = (url: string | undefined) =>
  url ? /^(https?:\/\/)[^\s/$.?#].[^\s]*$/i.test(url) : false

export const getImageUrl = (urlOrPath: string | undefined) => {
  if (!urlOrPath) return origin

  const isAbsoluteUrl = getIsAbsoluteUrl(urlOrPath)
  if (isAbsoluteUrl) return urlOrPath

  return `${origin}${urlOrPath}`
}

export const generatePartnerSession = ({
  queryParams,
  cookies,
}: {
  queryParams: ParsedUrlQuery
  cookies: { [key: string]: string }
}) => {
  const { partnerId: partnerIdFromQueryParam, sessionId: sessionIdFromQueryParam } = queryParams
  // Initially get it from Query Params if available
  let partnerSessionInfo: any = {
    partnerId: partnerIdFromQueryParam,
    sessionId: sessionIdFromQueryParam,
  }

  try {
    let partnerSessionFromCookie

    // Read from cookie if not available in query params
    if (!partnerIdFromQueryParam || !sessionIdFromQueryParam) {
      partnerSessionFromCookie = cookies[COOKIES_PARTNER_SESSION_ID]
        ? JSON.parse(cookies[COOKIES_PARTNER_SESSION_ID])
        : undefined

      // Set Partner Session Info only if it is from the list of partners
      if (PARTNER_LIST.includes(partnerSessionFromCookie?.partnerId)) {
        partnerSessionInfo = {
          partnerId: partnerSessionFromCookie?.partnerId,
          sessionId: partnerSessionFromCookie?.sessionId,
        }
      }
    }

    if (partnerSessionFromCookie?.expires < Date.now()) {
      partnerSessionInfo = undefined
    }
  } catch {
    // Do nothing
  }

  const hasPartnerSession = !!(partnerSessionInfo?.partnerId && partnerSessionInfo?.sessionId)
  const isPartnerFromQueryParams = hasPartnerSession && !!partnerIdFromQueryParam && !!sessionIdFromQueryParam

  return [partnerSessionInfo, isPartnerFromQueryParams, hasPartnerSession]
}

export const generateUserCurrency = ({
  hasPartnerSession,
  cookies,
  currencies,
  countryCode,
}: {
  hasPartnerSession: boolean
  cookies: Record<string, string>
  currencies: Currency[]
  countryCode: string
}) => {
  const currencyCookie = cookies[COOKIES_CURRENCY]
  if (!currencyCookie) {
    const countryBasedCurrency =
      (countryToCurrency as Record<string, string>)[
        hasPartnerSession ? PARTNER_DEFAULT_COUNTRY_CODE : countryCode
      ] || ''

    const currentCurrency =
      currencies.find((item) => item.currencyId === countryBasedCurrency)?.currencyId || FALLBACK_CURRENCY
    return currentCurrency
  } else if (hasPartnerSession) {
    const currentCurrency = PARTNER_DEFAULT_CURRENCY
    return currentCurrency
  }
  return null
}

export const generateDsUserId = ({ cookies }: { cookies: Record<string, string> }) => {
  const hasDsUserId = cookies[COOKIES_DS_USER_ID]
  if (!hasDsUserId) {
    return uuid()
  }
}

export const generateUtms = ({
  queryParams,
  cookies,
  referer,
}: {
  queryParams: ParsedUrlQuery
  cookies: Record<string, string>
  referer: string | undefined
}) => {
  const LAST_UTM = 0,
    LAST_CLICK = 1,
    FIRST_CLICK = 2

  const [utmParams, utmParamsWithAdditionalDetails, isRefererFromExcludeAttributionList] =
    getStringifiedUtmParams(queryParams, referer ? { referer } : {})
  const utmClicks: (null | string)[] = [null, null, null]

  if (utmParams) {
    utmClicks[LAST_UTM] = utmParamsWithAdditionalDetails
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (utmParams || (referer !== '' && referer?.indexOf(process.env.NEXT_PUBLIC_HOST!) === -1)) {
    if (!isRefererFromExcludeAttributionList) utmClicks[LAST_CLICK] = utmParamsWithAdditionalDetails
  }

  if (!cookies[COOKIES_FIRST_CLICK]) {
    const defaultValue = ' ' // string with empty space to avoid false condition next time when check above condition as this is one(first) time set only
    utmClicks[FIRST_CLICK] = utmParamsWithAdditionalDetails || defaultValue
  }

  return utmClicks
}

export const checkEmptySubSection = (subSections?: FormSectionItem[]) => {
  const haveComponentValue =
    Array.isArray(subSections) &&
    subSections.some(({ components }: any) => {
      const haveValue = components?.some(({ value }: FormField) => {
        return value !== null && value !== undefined && value !== ''
      })
      return haveValue
    })
  return haveComponentValue
}
