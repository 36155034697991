import { ProductTag } from 'lib/@Types'

import { FALLBACK_LOCALE, MILLION_VALUE, THOUSAND_VALUE } from 'lib/constants'

export const INITIAL_SHOWN_PRODUCT_REVIEW = 8
export const INITIAL_SHOWN_PRODUCT_REVIEW_MOBILE = 6
export const PRODUCT_REVIEW_PAGE_SIZE_20 = 20

export const PRODUCT_REVIEW_DEFAULT_SORT = 'MOST_RELEVANT'
export const MERCHANDISE_LABELS = {
  TAG_SPECIAL_DEALS: 'tag_special_deals',
  TAG_PELAGO_EXCLUSIVE: 'tag_pelago_exclusive',
}

const concatUnitNamesWithOr = (unitId: any, units: any) => {
  const unitObj = units[unitId]

  if (!unitObj) return

  const dependantUnitNames = unitObj?.dependantOn?.map?.((key: string) => units[key]?.unitName?.toString?.())

  let lastUnitName
  if (dependantUnitNames?.length > 1) {
    lastUnitName = dependantUnitNames?.pop()
    dependantUnitNames?.join(', ')
  }

  return {
    unitNames: dependantUnitNames,
    lastUnitName,
    dependantUnit: unitObj.unitName,
    count: unitObj.dependantOn?.length,
  }
}

export const validateMinMaxQuantity = (
  selection: {
    units?: TimeslotUnitSelection
    minimumQuantity: number | null
    maximumQuantity: number | null
  } | null
) => {
  if (!selection) return null

  const { units, minimumQuantity: optionMinimumQuantity, maximumQuantity: optionMaximumQuantity } = selection
  if (!units) return null

  // Get Total selected quantities across all the units
  const totalSelectedQuantity = Object.entries(units).reduce(
    (acc, [, { quantity, numberOfParticipants }]) => {
      // a group unit consisting of three individuals should have a participant count value set to 3,
      // which needs to be considered during quantity validation.
      const participants = numberOfParticipants || 1

      return acc + quantity * participants
    },
    0
  )

  if (totalSelectedQuantity === 0) return { key: 'minimumQuantity' }

  const unitsWithdependantOnValues: any = []
  const unitValidationError = { key: '', unitName: '' }

  // Unit level quantity validation
  const invalidUnitLevelMinMax = Object.keys(units).find((unitId) => {
    const { dependantOn, quantity, minimumQuantity, maximumQuantity, unitName, isRequired } = units[unitId]

    // If individual Unit is required OR selected `quantity` is > 0 then only validate
    if (!(isRequired || quantity)) return

    if (minimumQuantity && quantity < minimumQuantity) {
      unitValidationError.key = 'minimumQuantity'
      unitValidationError.unitName = unitName
      return true
    }

    if (maximumQuantity && quantity > maximumQuantity) {
      unitValidationError.key = 'maximumQuantity'
      unitValidationError.unitName = unitName
      return true
    }

    if (dependantOn?.length) {
      unitsWithdependantOnValues.push(unitId)
    }
  })

  if (invalidUnitLevelMinMax) {
    return unitValidationError
  }

  // Dependant Keys validations
  if (unitsWithdependantOnValues.length) {
    // Validate `dependantOn` keys of all units which has `dependantOn` values
    const invalidDependantOnFields = unitsWithdependantOnValues.find((unitId: any) => {
      const { quantity, dependantOn } = units[unitId]

      if (dependantOn?.length && quantity) {
        // Here, Inner loop will find at least one item with the 1 quantity.
        // Upon finding, it will return false (notice `return` with negate) and
        // consider it as passed validation.
        return !dependantOn.find((dependantUnitId: any) => units?.[dependantUnitId]?.quantity >= 1)
      }
    })

    if (invalidDependantOnFields) {
      const values = concatUnitNamesWithOr(invalidDependantOnFields, units)

      if (values) {
        return {
          key: 'dependantOn',
          ...values,
        }
      }
    }
  }

  // Total Quantity check
  if (!!optionMinimumQuantity && totalSelectedQuantity < optionMinimumQuantity)
    return { key: 'minimumQuantity' }
  if (!!optionMaximumQuantity && totalSelectedQuantity > optionMaximumQuantity)
    return { key: 'maximumQuantity' }

  return null
}

export const validateSelection = (selection: OptionSelection | null) => {
  if (!selection) return null

  const { selectors, timeSlots } = selection

  if (!timeSlots) return { key: 'time_slots' }

  const qtyValidation = validateMinMaxQuantity({
    units: selection.units as unknown as TimeslotUnitSelection,
    maximumQuantity: selection.maximumQuantity,
    minimumQuantity: selection.minimumQuantity,
  })

  if (qtyValidation) {
    return qtyValidation
  }

  const unselectedFields = Object.entries(selectors).filter(([, { value }]) => !value)
  if (unselectedFields.length !== 0) return { key: unselectedFields[0][0] }

  return null
}

export const validateUnitSelection = (
  selection: UnitSelectionState,
  selectedOption: ProductOption | null,
  productPackageSpecData: ProductPackageSpecDataState | null
) => {
  if (!selectedOption) {
    if (productPackageSpecData && productPackageSpecData.productPackageSpecs) {
      const unselectedSpecs = productPackageSpecData.productPackageSpecs.filter(
        (packageSpec) => !specs?.[packageSpec.specId]
      )
      if (unselectedSpecs.length !== 0) return { key: 'specs' }
    }

    return null
  }

  const { selectors, selectedTimeslots, units, specs } = selection
  const { allSelectors } = selectedOption

  if (!selectedTimeslots) return { key: 'time_slots' }

  const minQuantity = selectedOption?.minimumQuantity
  const maxQuantity = selectedTimeslots.maximumQuantity ?? selectedOption?.maximumQuantity
  const qtyValidation = validateMinMaxQuantity({
    units,
    minimumQuantity: minQuantity || null,
    maximumQuantity: maxQuantity || null,
  })

  if (qtyValidation) return qtyValidation

  const unselectedFields = (allSelectors || []).filter((selectorKey) => !selectors[selectorKey])
  if (unselectedFields.length !== 0) return { key: unselectedFields[0] }

  return null
}

// checks if format is HH:MM or HH:MM:SS
function isExpectedTimeFormat(time: string) {
  const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/
  return timeFormatRegex.test(time)
}

export const convertTo12Hour = (time: string, locale?: string) => {
  if (!time || typeof time !== 'string') return time
  if (typeof Date.prototype.toLocaleTimeString !== 'function') return time
  if (!isExpectedTimeFormat(time)) return time

  const [hour, minute, second] = time.split(':')
  const date = new Date()
  hour && date.setHours(parseInt(hour))
  minute && date.setMinutes(parseInt(minute))
  second && date.setSeconds(parseInt(second))

  return date.toLocaleTimeString(locale || FALLBACK_LOCALE, {
    hour: 'numeric',
    minute: '2-digit',
  })
}

export const cleanTagAndCategory = (tagOrCategory = '') => {
  if (typeof tagOrCategory !== 'string') {
    return tagOrCategory
  }

  return tagOrCategory.replace('tag_', '').replace('subcat_', '').replaceAll('_', '-')
}

// On local, set traveller-dev as host url to load images
const baseUrl = `${
  process.env.NODE_ENV === 'production' ? process.env.NEXT_PUBLIC_HOST : 'https://traveller.dev.pelago.com'
}/img/common/`

const sortedSize = [
  'webpXsmall',
  'webpSmall',
  'webpMedium',
  'webpLarge',
  'webpXlarge',
  'xsmall',
  'small',
  'medium',
  'large',
  'xlarge',
]

export const getProductPlaceholderImages = () => {
  const matchRegex = new RegExp('^(webp|)(xsmall|small|medium|large|xlarge)$', 'i')
  const pathToImage = `${baseUrl}default-product-image`
  const sizes = sortedSize.reduce((acc: any, size) => {
    const [, ext, imgSize] = size.match(matchRegex) || []

    const _imgSize = imgSize.toLowerCase()
    if (_imgSize) {
      acc[`${!ext ? '' : `${ext}-`}${imgSize.toLowerCase()}`] = `${pathToImage}-${_imgSize}.${ext || 'jpg'}`
      return acc
    }
  }, {})

  return {
    sizes,
    url: `${pathToImage}.jpg`,
  }
}

// all supported component types in order
const componentTypes = [
  'productOptionsComponent',
  'overviewComponent',
  'imageHighlightsComponent',
  'itineraryComponent',
  'featuresComponent',
  'customerReviewsComponent',
  'additionalInfoComponent',
  'locationComponent',
  'providerInfoComponent',
  'faqsComponent',
  'recommendedProductsComponent',
]

// order all the sections based on where the component type is placed on PDP page as per design
export const orderAllSections = (sections: string[], content: ProductContent): string[] => {
  if (!Object.keys(content).length || !sections.length) return []

  return (
    componentTypes.reduce((orderedSections: string[], componentType: string) => {
      const matchedSections = sections?.filter?.((section: string) => {
        // @ts-ignore
        return content[section]?.componentType === componentType
      })
      return [...orderedSections, ...(matchedSections || [])]
    }, []) || []
  )
}

export const getProductMerchandiseLabel = (
  productTags: ProductTag[] | undefined,
  merchandiseLabelMap = {
    [MERCHANDISE_LABELS.TAG_PELAGO_EXCLUSIVE]: 'exclusive',
    [MERCHANDISE_LABELS.TAG_SPECIAL_DEALS]: 'deal',
  }
) => {
  if (!productTags) return

  const merchandiseLabels = productTags.filter((tag) => Object.keys(merchandiseLabelMap).includes(tag.tagId))
  merchandiseLabels.sort((a, b) => {
    if (a.tagId === MERCHANDISE_LABELS.TAG_PELAGO_EXCLUSIVE) return -1
    if (b.tagId === MERCHANDISE_LABELS.TAG_PELAGO_EXCLUSIVE) {
      return 1
    }

    return 0
  })

  return {
    merchandiseLabel: merchandiseLabelMap[merchandiseLabels?.[0]?.tagId as keyof typeof merchandiseLabelMap],
  }
}

export const isOptionPackage = (
  productOption: ProductOptionPackage | null
): productOption is ProductPackage => {
  return productOption ? 'packageId' in productOption : false
}

export const getProductOptionPackage = (productOptionPackage: ProductOptionPackage | null) => {
  return {
    productOption: isOptionPackage(productOptionPackage) ? null : productOptionPackage,
    optionPackage: isOptionPackage(productOptionPackage) ? productOptionPackage : null,
  }
}

export const formatToNearestValue = (count: number, showMoreThanValue?: boolean) => {
  const isExceedMillion = count >= MILLION_VALUE
  const isExceedThousand = count >= THOUSAND_VALUE
  const symbol = isExceedMillion ? 'M' : isExceedThousand ? 'K' : ''
  const numToDivide = isExceedMillion ? MILLION_VALUE : isExceedThousand ? THOUSAND_VALUE : 1

  const value = !isExceedThousand ? Math.floor(count / 100) * 100 : count / numToDivide
  const isInteger = Number.isInteger(value)
  // we rounded down the value by multiplying by 10 and re divide it by 10 again
  // in order to show the correct decimal places
  // e.g. 1150 -> 1.1K, 1050 -> 1K
  const decimalValue = (Math.floor(value * 10) / 10).toFixed(1)
  const integerValue = Math.floor(value)
  const finalValue = isInteger ? integerValue : decimalValue

  const displayValue = `${finalValue}${symbol}`

  if (showMoreThanValue) {
    return `${displayValue}+`
  }

  return displayValue
}
