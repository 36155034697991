import React, {
  FC,
  useState,
  useRef,
  useEffect,
  useMemo,
  createContext,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { throttle } from 'lodash-es'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Icon } from 'ui/icon'

import { Link } from 'components/link'

import { useAppData } from 'lib/context/app-data-context'
import { buildPath, buildSlug, scrollTo } from 'lib/utils'

import { PRODUCT_ROUTE, SEARCH_ROUTE } from 'lib/constants/routes'
import { EVENTS } from 'lib/constants/events'

import { DestinationCard } from './result-card/destination-card'
import { ProductCard } from './result-card/product-card'
import { HeaderInput } from './inputs/header-input'
import { SeeMore } from './Info-headers/see-more'
import { Loader } from './Info-headers/loader'
import { Loader as SearchLoader } from './loader'
import { useSearchTrackEvent } from './hooks'
import { QueryCard } from './result-card/query-card'

import s from './styles.module.scss'

const CARD_TYPES: Record<string, 'product' | 'destination' | 'query'> = {
  product: 'product',
  destination: 'destination',
  query: 'query',
}

export type ResultsProps = SearchResultsComponentProps
export type ProductOption = SearchProductOption
export type DestinationOption = SearchDestinationOption

export interface AutocompleteContextProps {
  dropdownRef?: any
  cardRefs?: any
  value?: string
  variant?: AutoCompleteProps['variant']
  trackEvent?: TrackEventType
}

const INPUT_TOP_OFFSET_FROM_HEADER = -28

export const AutoCompleteContext = createContext<AutocompleteContextProps>({})

interface InputProps {
  placeholder?: string | React.ReactNode
  hideButton: boolean
  value: string
  inputRef?: React.Ref<HTMLInputElement>
  onClear: (e: React.MouseEvent<HTMLButtonElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  hasSearchIcon?: boolean
  noInputBorder?: boolean
  loading?: boolean
}

const SearchInput: FC<InputProps> = ({
  value,
  hideButton,
  placeholder,
  onChange,
  onClear,
  inputRef,
  hasSearchIcon,
  noInputBorder,
  loading,
}) => {
  return (
    <div className={cn(s.inputContainer, { [s.noInputBorder]: noInputBorder })}>
      <input
        ref={inputRef}
        value={value}
        placeholder={typeof placeholder === 'string' ? placeholder : undefined}
        className={hasSearchIcon ? 'pl-8' : ''}
        onChange={onChange}
      />
      {!value && placeholder && typeof placeholder !== 'string' && (
        <span className={s.placeholder}>{placeholder}</span>
      )}
      {hasSearchIcon && <Icon name="magnifying-glass" size="medium" className={s.searchIcon} />}
      {/* eslint-disable jsx-a11y/control-has-associated-label */}

      <Button
        iconName="delete-x"
        shape="circle"
        variant="tertiary-grey"
        className={cn(s.clearButton, { [s.hidden]: !value, ['mr-2']: value && !hideButton })}
        onClick={onClear}
      />

      {!hideButton && (
        <button type="submit" className={s.submitButton}>
          {!loading ? <Icon name="magnifying-glass" size="medium" /> : <SearchLoader variant="first-fold" />}
        </button>
      )}
    </div>
  )
}

const Results: FC<SearchResultsComponentProps> = ({
  options,
  sortedOptions,
  loading,
  onOptionClick,
  recommendType,
  cardRefStartIndex = 0,
  className,
}) => {
  const { cardRefs, dropdownRef, variant, value, trackEvent } =
    useContext<AutocompleteContextProps>(AutoCompleteContext)

  const { activityLog } = useAppData()

  const resultCardOptions = useMemo<ResultCardOption[] | []>(() => {
    const results: ResultCardOption[] = []
    if (sortedOptions && sortedOptions.length > 0) {
      sortedOptions.forEach((sortedItem, index) => {
        if ('productId' in sortedItem) {
          results.push({
            ...sortedItem,
            cardIndex: index,
            cardType: 'product',
            id: sortedItem.productId,
          })
        } else {
          results.push({
            ...sortedItem,
            cardIndex: index,
            cardType: 'destination',
            id: sortedItem.destinationId,
            code: sortedItem.code || Number(sortedItem.destinationCode),
          })
        }
      })
      return results
    }

    if (!options) {
      return []
    }

    Object.keys(options).forEach((key: string) => {
      if (key === 'destinations') {
        options[key]?.forEach((item) => {
          results.push({
            ...item,
            cardIndex: results.length,
            cardType: 'destination',
            id: item.destinationId,
            code: item.code || Number(item.destinationCode),
          })
        })
      } else if (key === 'products') {
        options[key]?.forEach((item) => {
          results.push({
            ...item,
            cardIndex: results.length,
            cardType: 'product',
            id: item.productId,
          })
        })
      } else if (key === 'queries') {
        options[key]?.forEach((item) => {
          results.push({
            query: item,
            cardIndex: results.length,
            cardType: 'query-suggestion',
            id: item,
          })
        })
      }
    })
    return results
  }, [options, sortedOptions])

  const { trackClick, trackExposure } = useSearchTrackEvent({
    trackEvent,
    recommendType,
    query: value,
    elementRefs: cardRefs,
    parentRef: dropdownRef,
    options: resultCardOptions,
    isCompact: variant === 'header',
    elementRefStartIndex: cardRefStartIndex,
  })

  useEffect(() => {
    const timeoutId = setTimeout(trackExposure, 100)

    return () => clearTimeout(timeoutId)
  }, [trackExposure])

  useEffect(() => {
    const _dropdownRef = dropdownRef.current
    if (!_dropdownRef) return

    const onScroll = throttle(trackExposure, 150)
    _dropdownRef?.addEventListener?.('scroll', onScroll)

    return () => _dropdownRef?.removeEventListener?.('scroll', onScroll)
  }, [dropdownRef, trackExposure])

  const handleDestinationSelect = (option: ResultCardDestinationOption) => {
    const { destinationId } = option
    if (!destinationId) return

    const index = resultCardOptions.findIndex((opt) => opt.destinationId === destinationId)
    trackClick(index)
    onOptionClick?.(option, CARD_TYPES.destination)
    activityLog.addItem({
      destination: {
        countryId: option.countryId,
        countryName: option.countryName,
        destinationCode: String(option.destinationCode),
        destinationId,
        name: option.name,
      },
    })
  }

  const handleProductSelect = (option: SearchProductOption) => {
    const { productId } = option
    if (!productId) return

    const index = resultCardOptions.findIndex(
      (opt) => opt.cardType === 'product' && opt.productId === productId
    )
    trackClick(index)
    onOptionClick?.(option, CARD_TYPES.product)
  }

  const handleQuerySuggestionClick = (option: ResultCardQueryOption) => {
    const { id } = option
    if (!id) return

    const index = resultCardOptions.findIndex((opt) => opt.cardType === 'query-suggestion' && opt.id === id)
    trackClick(index)
    onOptionClick?.(option, CARD_TYPES.query)
  }

  if (loading && !resultCardOptions.length) return <Loader />

  if (!resultCardOptions.length) return null

  return (
    <div className={className}>
      {resultCardOptions.map((option: ResultCardOption, index) => {
        if (option.cardType === 'destination') {
          return (
            <Link
              href={buildPath(
                SEARCH_ROUTE,
                {},
                {
                  destinationIds: option.destinationId,
                  sort: 'DS_POPULARITY_SCORE',
                }
              )}
              key={option.destinationId}
              passHref
            >
              <DestinationCard
                {...option}
                key={option.destinationId}
                onClick={() => handleDestinationSelect(option)}
                ref={(ref) => {
                  cardRefs.current[index + cardRefStartIndex] = ref
                }}
              />
            </Link>
          )
        }

        if (option.cardType === 'query-suggestion') {
          return (
            <Link
              // adding index to the key because sometimes BE returns the same option.query here
              // and it causes issue with UI since React doesn't know what to update
              key={`${option.query}-${index}`}
              href={buildPath(SEARCH_ROUTE, {}, { keyword: option.query })}
              passHref
            >
              <QueryCard
                {...option}
                onClick={() => handleQuerySuggestionClick(option)}
                ref={(ref) => {
                  cardRefs.current[index + cardRefStartIndex] = ref
                }}
              />
            </Link>
          )
        }

        const { productId } = option
        const productSlug = buildSlug({
          id: productId,
          uri: option?.productUri,
          destinationId: option?.destinationId,
        })

        return (
          <Link href={buildPath(PRODUCT_ROUTE, { productSlug })} key={option.productId} passHref>
            <ProductCard
              {...option}
              key={option.productId}
              onClick={() => handleProductSelect(option)}
              ref={(ref) => {
                cardRefs.current[index + cardRefStartIndex] = ref
              }}
            />
          </Link>
        )
      })}
    </div>
  )
}

Results.displayName = 'Results'

export interface AutoCompleteProps {
  hideButton?: boolean
  placeholder?: string | React.ReactNode
  attached?: boolean
  actAsButton?: boolean
  hasSearchIcon?: boolean
  submitOnClear?: boolean
  unstyledDropdown?: boolean
  alwaysShowDropdown?: boolean
  focusOnLoad?: boolean
  showDropdownOnFocus?: boolean
  scrollToTop?: boolean
  maxDropdownWidth?: string
  minDropdownWidth?: string | null
  // this used for gplp mobile where there is back button before search input
  subVariant?: 'gplp' | null
  size?: 'medium' | 'medium-large' | 'semi-large' | 'large' | 'x-large'
  variant?: 'header' | 'white-rounded' | 'gplp-rounded' | 'hp'
  initialStateComponent?: React.ReactNode
  children: JSX.Element[] | JSX.Element
  trackEvent?: TrackEventType
  ref?: any
  value: string
  pressEnterAsSubmit?: boolean
  allowEmptyValueSubmit?: boolean
  noInputBorder?: boolean
  onClick?: () => void
  onChange: (value: string) => void
  onSubmit?: (query: string, e?: any) => void
  dontTrackOnSubmit?: boolean
  loading?: boolean
}

const SearchAutocomplete = forwardRef<any, AutoCompleteProps>(
  (
    {
      value,
      variant,
      placeholder,
      children,
      onChange,
      hideButton,
      attached,
      initialStateComponent,
      subVariant,
      onSubmit,
      size = 'medium',
      actAsButton,
      hasSearchIcon,
      submitOnClear = false,
      focusOnLoad,
      showDropdownOnFocus = true,
      scrollToTop = true,
      unstyledDropdown,
      maxDropdownWidth = '100%',
      minDropdownWidth = null,
      alwaysShowDropdown,
      pressEnterAsSubmit = true,
      allowEmptyValueSubmit,
      noInputBorder,
      trackEvent,
      onClick,
      dontTrackOnSubmit,
      loading,
    },
    forwardedRef
  ) => {
    const blurTimeoutRef = React.useRef<any>(null)
    const autoScrollTimeoutRef = React.useRef<any>(null)
    const cardRefs = useRef<HTMLDivElement[]>([])
    const formRef = useRef<HTMLFormElement>(null)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const { activityLog } = useAppData()

    const [focusedCardIndex, setFocusedCardIndex] = useState<number | undefined>(undefined)

    const inputRef = useRef<HTMLInputElement>(null)
    const [focused, setFocused] = useState(false)

    const enableFocus = () => {
      if (blurTimeoutRef.current) clearTimeout(blurTimeoutRef.current)
      if (autoScrollTimeoutRef.current) clearTimeout(autoScrollTimeoutRef.current)

      if (scrollToTop) {
        autoScrollTimeoutRef.current = setTimeout(
          () =>
            inputRef.current &&
            variant !== 'header' &&
            scrollTo(inputRef.current, INPUT_TOP_OFFSET_FROM_HEADER),
          100
        )
      }
      setFocused(true)
    }

    const closeDropdown = () => {
      setFocused(false)
    }

    const handleSubmit = (e?: any) => {
      e?.preventDefault?.()
      if (!value && !allowEmptyValueSubmit) {
        inputRef.current?.focus()
        return
      }
      closeDropdown()

      if (!pressEnterAsSubmit) return
      if (!dontTrackOnSubmit) {
        trackEvent?.({
          attributeId: EVENTS.AUTO_SUGGEST_SEARCH_INPUT,
          attributeType: EVENTS.ATTRIBUTES_TYPE.INPUT,
          isCompact: variant === 'header',
          attributeValue: {
            query: value,
          },
        })
      }
      if (value) activityLog.addItem({ keyword: value })
      onSubmit?.(value, e)
    }

    useImperativeHandle<React.Ref<any>, any>(forwardedRef, () => ({
      submit: handleSubmit,
      focus: () => inputRef.current?.focus?.(),
      blur: () => inputRef.current?.blur(),
    }))

    const clearFocusedCardIndex = () => setFocusedCardIndex(undefined)

    useEffect(() => {
      if (focusOnLoad) {
        setTimeout(() => {
          inputRef?.current?.focus()
        }, 100)
      }

      const clickOutsideHandler = ({ target }: any) => {
        if (!inputRef?.current?.contains(target)) {
          clearFocusedCardIndex()
          closeDropdown()
        }
      }
      document.addEventListener('click', clickOutsideHandler)

      return () => {
        document.removeEventListener('click', clickOutsideHandler)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      onChange?.('')

      if (submitOnClear) {
        onSubmit?.('', e)
        closeDropdown()
      } else {
        enableFocus()
        inputRef.current?.focus()
      }
    }

    const handleKeydown = (event: React.KeyboardEvent<HTMLFormElement>) => {
      const _cardRefs = cardRefs.current?.filter?.(Boolean)
      if (!cardRefs.current.length) return

      if (event.key === 'ArrowDown') {
        event.preventDefault()
        if (focusedCardIndex === undefined || focusedCardIndex === _cardRefs.length - 1) {
          _cardRefs[0]?.focus()
          setFocusedCardIndex(0)
        } else {
          _cardRefs[focusedCardIndex + 1]?.focus()
          setFocusedCardIndex((curr) => {
            if (curr !== undefined) return curr + 1
            return curr
          })
        }
      } else if (event.key === 'ArrowUp') {
        event.preventDefault()
        if (focusedCardIndex === undefined || focusedCardIndex === 0) {
          const lastIndex = _cardRefs.length - 1
          _cardRefs[lastIndex]?.focus()
          setFocusedCardIndex(lastIndex)
        } else {
          _cardRefs[focusedCardIndex - 1]?.focus()
          setFocusedCardIndex((curr) => {
            if (curr !== undefined) return curr - 1
            return curr
          })
        }
      } else if (event.key === 'Enter') {
        if (focusedCardIndex !== undefined) {
          _cardRefs[focusedCardIndex]?.click()
        } else {
          handleSubmit(event)
        }
      }
    }

    const className =
      variant === 'header'
        ? cn(s.headerSearchAutocomplete)
        : cn(s.searchAutocomplete, s[`is-${size}`], s[`variant-${variant}`], s['with-clear'], {
            [s['is-attached']]: !!attached,
            [s['with-button']]: !hideButton,
            [s['act-as-button']]: !!actAsButton,
            [s['with-search-icon']]: !!hasSearchIcon,
            [s['unstyled-dropdown']]: !!unstyledDropdown,
          })

    const handleFocus = () => {
      if (actAsButton || !showDropdownOnFocus) return
      enableFocus()
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!focused && !actAsButton) enableFocus()

      onChange(e.target.value || '')
    }

    const handleOnBlur = () => {
      if (focusedCardIndex === undefined) {
        blurTimeoutRef.current = setTimeout(() => setFocused(false), 200) // allow time to click on link
      }
    }

    const showChildren = (focused || focusedCardIndex !== undefined) && value

    // remove keyboard card index focus if user is using mouse
    const handleMouseMove = useMemo(
      () =>
        throttle(() => {
          if (focusedCardIndex) {
            cardRefs.current[focusedCardIndex]?.blur()
          }
        }, 300),
      [focusedCardIndex]
    )

    const showInitialComponent = (focused && !value) || (alwaysShowDropdown && !value)

    return (
      <AutoCompleteContext.Provider value={{ cardRefs, variant, value, trackEvent, dropdownRef }}>
        <form
          ref={formRef}
          onFocus={handleFocus}
          onKeyDown={handleKeydown}
          className={className}
          onSubmit={handleSubmit}
          onClick={onClick}
          onBlur={handleOnBlur}
        >
          {variant === 'header' ? (
            <HeaderInput
              inputRef={inputRef}
              value={value}
              onChange={handleChange}
              placeholder={typeof placeholder === 'string' ? placeholder : undefined}
              loading={loading}
              onClear={handleClear}
            />
          ) : (
            <SearchInput
              hasSearchIcon={hasSearchIcon}
              inputRef={inputRef}
              value={value}
              hideButton={!!hideButton}
              onChange={handleChange}
              placeholder={placeholder}
              onClear={handleClear}
              noInputBorder={noInputBorder}
              loading={loading}
            />
          )}
          <div
            ref={dropdownRef}
            className={cn(s.dropdown, { [s.gplp]: subVariant === 'gplp' })}
            style={{
              maxWidth: maxDropdownWidth,
              ...(!!minDropdownWidth && { minWidth: minDropdownWidth, width: 'max-content' }),
            }}
            onMouseMove={handleMouseMove}
          >
            {showInitialComponent && initialStateComponent}
            {(showChildren || (alwaysShowDropdown && !showInitialComponent)) && children}
          </div>
        </form>
      </AutoCompleteContext.Provider>
    )
  }
)
SearchAutocomplete.displayName = 'SearchAutoComplete'

export {
  SearchAutocomplete,
  Results as SearchAutocompleteResults,
  SeeMore as SearchAutocompleteSeeMore,
  SearchInput,
}
