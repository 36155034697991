import { useRef, FC } from 'react'
import cn from 'classnames'

import useLayoutEffect from 'lib/hooks/useLayoutEffect'

import { useGlobalContext } from 'lib/context/global-context'

import s from './styles.module.scss'

interface TooltipProps {
  text: string
  active?: boolean
  position?: 'top' | 'bottom' | 'left' | 'right' | 'bottom-end'
  variant?: 'default' | 'white'
  behaviour?: 'popover' | 'popover-overlay'
  onClose?: () => void
}

const Tooltip: FC<TooltipProps> = ({
  children,
  text,
  active = true,
  position = 'top',
  variant = 'default',
  behaviour = 'popover',
  onClose,
}) => {
  const triggerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const arrowRef = useRef<HTMLDivElement>(null)

  const { isMobileView } = useGlobalContext()

  // `overlayRef`: In case of popover-overlay, it will show popover with overlay
  const overlayRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!triggerRef.current || !tooltipRef.current || !active) return

    const childElement = triggerRef.current?.firstElementChild
    const isChildDisabled = childElement?.getAttribute?.('disabled') !== null

    // disabled elements don't trigger events, so we need to use the parent element as trigger to listen to events and show the tooltip
    const trigger = isChildDisabled ? triggerRef.current : childElement
    const tooltip = tooltipRef.current

    const importPopper = async () => {
      const createPopper = await import('@popperjs/core').then((module: any) => module.createPopper)
      const popperInstance = createPopper(trigger, tooltip, {
        placement: position,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'arrow',
            options: {
              element: arrowRef.current,
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
            },
          },
          {
            name: 'flip',
            options: {
              altAxis: true,
              fallbackPlacements: ['top', 'bottom', 'left', 'right'],
            },
          },
        ],
      })

      const show = () => {
        // make the tooltip visible
        tooltip.setAttribute('data-show', '')

        // enable the event listeners
        popperInstance.setOptions((options: any) => ({
          ...options,
          modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }],
        }))

        // update its position
        popperInstance.update()
      }

      const hide = () => {
        // hide the tooltip
        tooltip.removeAttribute('data-show')

        // disable the event listeners
        popperInstance.setOptions((options: any) => ({
          ...options,
          modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }],
        }))

        onClose?.()
      }

      if (behaviour === 'popover') {
        const enterEevents = isMobileView ? ['touchstart'] : ['mouseenter', 'focus']
        const leaveEvents = isMobileView ? ['touchend'] : ['mouseleave', 'blur']

        enterEevents.forEach((event) => trigger.addEventListener(event, show))

        leaveEvents.forEach((event) => trigger.addEventListener(event, hide))
      } else {
        active && show()

        const leaveElements = ['click']

        leaveElements.forEach((event: any) => {
          // Close the popover clicking on popover or overlay
          overlayRef.current && overlayRef.current.addEventListener(event, hide)
          tooltipRef.current && tooltipRef.current.addEventListener(event, hide)
        })
      }
    }

    importPopper()
  }, [active, behaviour, onClose, position, isMobileView])

  if (!active) return <>{children}</>

  return (
    <div className={cn(s.popperContainer, s[behaviour])}>
      <div className={s.popper}>
        <span ref={triggerRef} className={s.trigger}>
          {children}
        </span>

        <div
          role="tooltip"
          ref={tooltipRef}
          className={cn(s.tooltip, { [s['white-variant']]: variant === 'white' })}
          data-popper-placement={position}
        >
          {text}
          <div ref={arrowRef} className={s.arrow}></div>
        </div>

        <div ref={overlayRef} className={s.overlay}></div>
      </div>
    </div>
  )
}

export { Tooltip }
